<template>
  <div>
    <el-form :model="formOtherPerson" :rules="rulesOtherPerson" v-show="!isPersonReceiver" ref="orderOtherPersonForm"
      label-width="auto" label-position="top" class="hodo-el-form">
      <div class="mb-3 pl-2 fw-500 border-l-4 border-t-0 border-r-0 border-b-0 border-solid border-blue-800">{{
      $t("order_confirm.lbl_result_recipient") }}</div>
      <div class="row mb-2">
        <div class="col-md-6">
          <el-form-item :label="$t('order_confirm.lbl_fullname')" prop="name">
            <el-input v-model="formOtherPerson.name" :disabled="isView"></el-input>
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item :label="$t('order_confirm.lbl_phone_number')" prop="phone">
            <el-input v-model="formOtherPerson.phone" :disabled="isView"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <el-form-item :label="$t('order_confirm.lbl_address1')" prop="address1">
            <el-input v-model="formOtherPerson.address1" :disabled="isView"></el-input>
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item :label="$t('order_confirm.lbl_address2')" prop="address2">
            <el-input v-model="formOtherPerson.address2" :disabled="isView"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <el-form-item :label="$t('order_confirm.lbl_email')" prop="email">
            <el-input v-model="formOtherPerson.email" :disabled="isView"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <el-form-item :label="$t('order_confirm.lbl_medical_history')" prop="medicalHistory" v-if="isIncludeService">
            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" v-model="medicalHistory" :disabled="isView"
              :placeholder="$t('order_confirm.lbl_medical_history_example')"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="row mb-2" v-if="!isView && isIncludeService">
        <div class="col-md-6">
          <div class="upload-btn" @click="handleUploadDocs()">
            <img src="/icons/u_upload.svg" alt="upload" width="20" height="20" />
            <span class="fs-14 txt-pri">
              {{
      $t("lbl_upload_test_and_paraclinical_results_files")
    }}
            </span>
          </div>
          <input :ref="`uploadMedicalHistory`" class="d-none" type="file" @change="(e) => handleSelectFileUpdate(e)"
            multiple />
        </div>
      </div>
      <div class="row" v-for="(item, index) in historyMedicalAttachments" :key="index">
        <div class="col-md ml-1">
          <template v-if="!isView">
            <span @click="handleOpenFile(item.file)" class="cursor-pointer">{{ getFileName(item.file) }}</span>
            <span class="ml-4 cursor-pointer hover:text-red-400" @click="removeAttachmentItem(index)">
              <i class="el-icon-close text-base"></i>
            </span>
          </template>
          <a :href="item.url" target="_blank" class="cursor-pointer" v-else>
            {{
      getFileName(item.url)
    }}
          </a>
        </div>
      </div>
    </el-form>
    <el-form :model="form" :rules="rules" ref="orderPersonForm" label-width="auto" label-position="top"
      class="hodo-el-form mt-4">
      <div class="mb-3 pl-2 fw-500 border-l-4 border-t-0 border-r-0 border-b-0 border-solid border-blue-800">
        {{
      $t(
        isProductService
          ? "order_confirm.lbl_service_requester"
          : "lbl_orderer"
      )
    }}
      </div>
      <div class="row mb-2">
        <div class="col-md-6">
          <el-form-item :label="$t('order_confirm.lbl_fullname')" prop="name">
            <el-input v-model="form.name" :disabled="isView"></el-input>
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item :label="$t('order_confirm.lbl_phone_number')" prop="phone">
            <el-input v-model="form.phone" :disabled="isView"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <el-form-item :label="$t('order_confirm.lbl_address1')" prop="address1">
            <el-input v-model="form.address1" :disabled="isView"></el-input>
          </el-form-item>
        </div>
        <div class="col-md-6">
          <el-form-item :label="$t('order_confirm.lbl_address2')" prop="address2">
            <el-input v-model="form.address2" :disabled="isView"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <el-form-item :label="$t('order_confirm.lbl_email')" prop="email">
            <el-input v-model="form.email" :disabled="isView"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>

    <el-form>
      <div class="row mb-2">
        <div class="col-md-12">
          <el-form-item :label="$t('order_confirm.lbl_notes')" prop="notes">
            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" v-model="notes"
              :disabled="isView"></el-input>
          </el-form-item>
        </div>
      </div>
      <template v-if="isPersonReceiver">
        <div class="row">
          <div class="col-md-12">
            <el-form-item :label="$t('order_confirm.lbl_medical_history')" prop="medicalHistory">
              <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" v-model="medicalHistory"
                :disabled="isView" :placeholder="$t('order_confirm.lbl_medical_history_example')"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row" v-for="(item, index) in historyMedicalAttachments" :key="index">
          <div class="col-md ml-1">
            <template v-if="!isView">
              <span @click="handleOpenFile(item.file)" class="cursor-pointer">{{ getFileName(item.file) }}</span>
              <span class="ml-4 cursor-pointer hover:text-red-400" @click="removeAttachmentItem(index)">
                <i class="el-icon-close text-base"></i>
              </span>
            </template>
            <a :href="item.url" target="_blank" class="cursor-pointer" v-else>
              {{
      getFileName(item.url)
    }}
            </a>
          </div>
        </div>
      </template>
    </el-form>
    <ModalConfirmShareInformation ref="modalConfirmShareInformation" @confirm="confirmShareInformation"
      :isProductService="isProductService" />
  </div>
</template>

<script>
import ModalConfirmShareInformation from '@/components/CustomModal/ModalConfirmShareInformation.vue'
import appUtils from '../../utils/appUtils'
import i18n from '@/lang/index.js'

export default {
  name: 'OrderPersonInfo',
  components: {
    ModalConfirmShareInformation
  },
  props: {
    ctaSubmit: Number,
    personInfo: Object,
    isProductService: Boolean,
    isUploadFullRequirement: Boolean
  },
  data () {
    const initialForm = {
      name: '',
      phone: '',
      address1: '',
      address2: '',
      email: ''
    }
    const initialRules = {
      name: [
        {
          required: true,
          message: i18n.t('order_confirm.lbl_msg_require_receipient_name'),
          trigger: ['blur', 'change']
        }
      ],
      phone: [
        {
          required: true,
          message: i18n.t('order_confirm.lbl_msg_require_receipient_number'),
          trigger: ['blur', 'change']
        },
        {
          trigger: ['blur', 'change'],
          validator (rule, value, callback) {
            if (isNaN(Number(value))) {
              callback(
                new Error(i18n.t('order_confirm.lbl_msg_require_number'))
              )
            } else {
              callback()
            }
          }
        },
        {
          trigger: ['blur', 'change'],
          validator (rule, value, callback) {
            if (value.toString().length < 10 || value.toString().length > 12) {
              callback(
                new Error(
                  i18n.t('order_confirm.lbl_msg_phone_number_length_error')
                )
              )
            } else {
              callback()
            }
          }
        },
        {
          pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
          message: i18n.t('message_errors.phone_invalidate'),
          trigger: ['blur', 'change']
        }
      ],
      email: [
        {
          required: false,
          message: i18n.t('order_confirm.lbl_msg_require_email'),
          trigger: ['blur', 'change']
        }
      ],
      address2: [
        {
          required: true,
          message: i18n.t('order_confirm.lbl_msg_require_address2'),
          trigger: ['blur', 'change']
        }
      ]
    }
    return {
      isView: false,
      form: {
        ...initialForm
      },
      formOtherPerson: {
        ...initialForm,
        meta_data: ''
      },
      notes: '',
      rules: {
        ...initialRules
      },
      rulesOtherPerson: {
        ...initialRules
      },
      isPersonReceiver: false,
      medicalHistory: '',
      historyMedicalAttachments: []
    }
  },
  computed: {
    isIncludeService () {
      return this.$shoppingCart.map(elm => elm.type)?.includes(2)
    }
  },
  beforeMount () {
    if (this.personInfo) {
      const { orderer, receiver, isPersonReceiver, notes } = this.personInfo
      this.isView = true
      this.isPersonReceiver = isPersonReceiver
      this.form = { ...orderer }
      this.formOtherPerson = { ...receiver }
      this.notes = notes
      this.rules = null
      this.rulesOtherPerson = null
      const receiverMetadata = receiver?.meta_data
        ? JSON.parse(receiver.meta_data)
        : {}
      this.medicalHistory = receiverMetadata?.medicalHistory || ''
      this.historyMedicalAttachments =
        receiverMetadata?.attachments.map(url => ({ url, file: null })) || []
      return
    }

    if (this.$user) {
      const {
        name,
        phone,
        email,
        address,
        address_district_name,
        address_province_name,
        address_wards_name
      } = this.$user?.person || {}

      let provinceStr = (address_wards_name && `${address_wards_name}, `) || ''
      provinceStr +=
        (address_district_name && `${address_district_name}, `) || ''
      provinceStr +=
        (address_province_name && `${address_province_name}, `) || ''

      this.form = {
        ...this.form,
        name,
        phone: phone,
        address2: address,
        address1: provinceStr || '',
        email: email
      }
      this.formOtherPerson = {
        ...this.formOtherPerson,
        name,
        phone: phone,
        address2: address,
        address1: provinceStr || '',
        email: email
      }
    }
  },
  watch: {
    ctaSubmit () {
      if (this.isPersonReceiver) {
        this.submitForm(['orderPersonForm'])
      } else {
        this.submitForm(['orderPersonForm', 'orderOtherPersonForm'])
      }
    }
  },
  methods: {
    submitForm (formNameArr) {
      let isError = false
      // eslint-disable-next-line no-unused-expressions
      formNameArr?.map(name => {
        this.$refs[name].validate(valid => {
          if (!valid) {
            window.alert('Thông tin người đặt không được để trống')
            isError = true
            return false
          }
        })
      })

      if (!this.isUploadFullRequirement) {
        window.alert('Các tài liệu đính kèm bắt buộc không được để trống')
        isError = true
        return false
      }
      if (isError) {
        return
      }
      this.$refs.modalConfirmShareInformation.showModalConfirm(true)
    },
    confirmShareInformation () {
      this.formOtherPerson.meta_data = JSON.stringify({
        medicalHistory: this.medicalHistory,
        attachments: this.historyMedicalAttachments.map(item => item.url)
      })
      this.$emit('handlePayment', {
        orderer: this.form,
        receiver: this.isPersonReceiver ? this.form : this.formOtherPerson,
        isPersonReceiver: this.isPersonReceiver,
        notes: this.notes
      })
    },
    resetForm (formName) {
      this.$refs[formName[0]].resetFields()
    },
    handleChangePersonReceiver (status) {
      if (status) {
        this.resetForm(['orderOtherPersonForm'])
      }
    },

    // Upload attachment resources
    /**
     * @param {{url: String, file: File}} item
     */
    async handleUpload (item) {
      try {
        this.isLoading = true
        const params = {
          file: item.file,
          FolderTarget: 'resources/orderAttachments',
          IdTarget: this.$user.id || 0
        }
        const formData = new FormData()

        for (const key in params) {
          formData.append(key, params[key])
        }

        await this.$rf
          .getRequest('AuthRequest')
          .uploadFileTenant(formData)
          .then(res => {
            const url = res.data.fileUrl
            item.url = url
          })
        this.$message({
          message: this.$t('order_confirm.lbl_upload_file_success'),
          type: 'success'
        })
      } catch (error) {
        console.log(error)
        this.$message({
          message: this.$t('order_confirm.lbl_upload_file_fail'),
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    handleUploadDocs () {
      this.$refs.uploadMedicalHistory.click()
    },
    removeAttachmentItem (index) {
      this.historyMedicalAttachments.splice(index, 1)
    },
    handleSelectFileUpdate (e) {
      if (e?.target?.files?.length <= 0) return
      if (e?.target?.files?.length > 1) {
        for (let index = 0; index < e?.target?.files.length; index++) {
          const item = {
            url: '',
            file: e?.target?.files[index]
          }
          this.historyMedicalAttachments.push(item)
          this.handleUpload(item)
        }
      } else {
        const item = {
          url: '',
          file: e.target.files[0]
        }
        this.historyMedicalAttachments.push(item)
        this.handleUpload(item)
      }
    },

    /**
     * @param {File} item
     */
    handleOpenFile (item) {
      window.open(URL.createObjectURL(item), '_blank')
    },

    /**
     * @param {File} item
     */
    getFileName (item) {
      console.log('>>> / file: OrderPersonInfo.vue:390 / item:', item)
      if (typeof item === 'string' && item.includes('https')) {
        const fileName = item.substring(item.lastIndexOf('/') + 1)
        return appUtils.truncateHash(fileName, 15)
      } else {
        return appUtils.truncateHash(item?.name, 15)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .hodo-el-form {
    .el-form-item {
      &__label {
        width: 100%;
        line-height: 1;
      }
    }
  }
}

.upload-btn {
  width: 100%;
  border: 1px dashed #20419b;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 9px;
  cursor: pointer;
  gap: 4px;
}
</style>
