<template>
  <div
    class="modal fade"
    id="modalConfirmPac"
    tabindex="-1"
    aria-labelledby="modalConfirmPac"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      :class="isProductService ? 'modal-lg' : 'modal-md'"
    >
      <div class="modal-content radius-15 p-4">
        <div class="mb-3">
          <div class="text-right flex justify-between items-center">
            <div class="fs-20 fw-600 uppercase">
              {{
                $t(
                  isProductService
                    ? "Yêu cầu đồng thuận từ Khách hàng"
                    : "Xác nhận đặt hàng"
                )
              }}
            </div>
            <svg
              @click="showModalConfirm(false)"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="cursor-pointer"
            >
              <path
                d="M6 18.4998L17.9998 6.5"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.9998 18.4998L6 6.5"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <div v-if="isProductService" class="text-black">
          <p>
            <strong>
              {{
                $t(
                  "Khách hàng đồng ý chia sẻ thông tin chỉ định y khoa. Cụ thể là:"
                )
              }}
            </strong>
          </p>
          <!-- <li style="word-break: break-word">
              {{
                $t(
                  "Khách hàng chấp thuận cho phép Bác Sĩ, Nhân Viên Y Tế yêu cầu dịch vụ cận lâm sàng tại Đơn vị này"
                )
              }}
          </li>-->
          <div class="mt-2" style="word-break: break-word">
            {{
              $t(
                "Khách hàng chấp thuận cho phép Nhân Viên Y Tế, Cơ Sở Y Tế, Cơ Sở Cung Cấp Dịch Vụ Y Tế và Nền Tảng Công Nghệ Thông Tin chia sẻ dữ liệu. Cụ thể là:"
              )
            }}
            <ul>
              <li style="word-break: break-word">
                {{ $t("Dữ liệu hành chính (bao gồm thông tin cá nhân)") }}
              </li>
              <li style="word-break: break-word">
                {{ $t("Dữ liệu lâm sàng kèm theo") }}
              </li>
              <li style="word-break: break-word">
                {{ $t("Dữ liệu khách hàng đính kèm (nếu có)") }}
              </li>
            </ul>
          </div>
          <p style="word-break: break-word; font-style: italic">
            {{
              $t(
                "Lưu ý: Dữ liệu được chia sẻ hoàn toàn nhằm mục đích đảm bảo chất lượng Dịch Vụ Xét Nghiệm, đảm bảo an toàn Khách hàng, Nhân Viên Y Tế, và các Đơn Vị Cung Cấp Dịch Vụ."
              )
            }}
          </p>
        </div>
        <div v-else class="text-black">
          <div>{{ $t("Bạn có chắc chắn muốn đặt đơn hàng này không?") }}</div>
        </div>
        <div class="flex justify-end mt-5 space-x-2">
          <button
            class="btn btn-light w-40 p-2"
            @click="showModalConfirm(false)"
          >
            Hủy
          </button>
          <button @click="confirm" class="btn btn-pri w-40 p-2">
            {{ $t(isProductService ? "Đồng ý & tiếp tục" : "Xác nhận") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalConfirmShareInformation',
  props: {
    isProductService: Boolean
  },
  data () {
    return {}
  },
  methods: {
    confirm () {
      this.$emit('confirm')
      this.showModalConfirm(false)
    },
    showModalConfirm (show) {
      window.$('#modalConfirmPac').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

  <style lang="css" scoped>
</style>
